import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import { SubmitHandler, useForm } from 'react-hook-form'
import useBrandLegalResourcesMutation from '@hooks/queries/useBrandLegalResources'
import { useFormBrand } from '@hooks/useFormBrand'
import { CircularProgress } from '@material-ui/core'
import useBrandMutation from '@hooks/queries/useBrand'
import LegalText from '../LegalText'

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: '100%',
    marginBottom: 15,
    '& label + .MuiInput-formControl': {
      marginTop: 24,
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before,& .MuiInput-underline:before,& .MuiInput-underline:after':
      {
        border: 'none',
      },
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    lineHeight: 1.6,
    letterSpacing: '.9px',
    textTransform: 'uppercase',
    color: theme.palette.grey[400],
  },
  fieldInput: {
    width: '100%',
    height: 40,
    padding: '18px 22px 0',
    borderRadius: 12,
    border: '2px solid #0000',
    '&:focus': {
      borderColor: theme.palette.primary.light,
      borderRadius: 12,
    },
    background:
      theme.palette.type === 'light' ? theme.palette.grey[600] : '#e4e4e408',
    fontSize: 14,
    fontWeight: 600,
    color: theme.palette.text.hint,
    transition: 'all .2s',
    outline: 'none',
  },
  btn: {
    marginBottom: 16,
    minWidth: '100%',
    backgroundColor: theme.palette.primary.light,
    color: '#fff',
    height: 48,
    padding: '0 24px',
    borderRadius: 12,
    fontSize: 14,
    fontWeight: 600,
    transition: 'all .2s',
    '&:disabled': {
      backgroundColor: theme.palette.grey[400],
      cursor: 'not-allowed',
    },
  },
  fieldError: {
    display: 'flex',
    flexWrap: 'wrap',
    textAlign: 'center',
  },
  invalidFeed: {
    width: '100%',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.error.main,
    '&:first-child': {
      marginBottom: 10,
    },
  },
}))

export type InputsSettlementPage = {
  settlement: string
}

type OnSubmitSettlement = {
  settlement: string
}

const CreateSettlement = ({ brandName }: { brandName: string }) => {
  const classes = useStyles()

  const { isLoading, createBrandSettlement, updateSettlement } =
    useBrandLegalResourcesMutation()
  const { brandId, setOpen, settlement } = useFormBrand()
  const { updateSettlementStatus } = useBrandMutation()

  const {
    register,
    handleSubmit,
    control,
    clearErrors,
    setValue,
    formState: { errors },
  } = useForm<InputsSettlementPage>()

  const rules = {
    settlement: register('settlement'),
  }

  const initialValue = `<h3>Réglement | ${brandName}</h3>`

  const onSubmit: SubmitHandler<OnSubmitSettlement> = async (data) => {
    if (brandId) {
      try {
        if (settlement && settlement.settlement.length > 0) {
          await updateSettlement({
            brand: brandId,
            ...data,
          })
        } else {
          const { data: result } = await createBrandSettlement({
            brand: brandId,
            ...data,
          })

          if (result) {
            await updateSettlementStatus({
              id: brandId,
              settlementStatus: true,
            })
          }
        }

        setOpen(false)
        setValue('settlement', '')
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <LegalText
        control={control}
        name={rules.settlement.name}
        initialValue={settlement ? settlement.settlement : initialValue}
      />
      <button type="submit" className={classes.btn}>
        {isLoading ? (
          <CircularProgress
            size={20}
            style={{
              color: '#fff',
            }}
          />
        ) : (
          'Enregistrer'
        )}
      </button>
    </form>
  )
}

export default CreateSettlement
